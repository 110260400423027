// localStorageに値がない場合はOS設定を、あればlocalStorageの値でダークモード有効化を判別
const isDarkmode = typeof localStorage.theme === 'undefined'
  ? window.matchMedia('(prefers-color-scheme: dark)').matches
  : localStorage.theme === 'dark'

const toggleDarkmodeClassName = (isDarkmode) => {
  if (isDarkmode) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

// カスタムロゴを切り替える
const toggleCustomLogo = (isDarkmode) => {
  // eslint-disable-next-line no-undef
  const { darkLogo, customLogo } = SUDM

  // カスタムロゴ要素を取得
  const $logos = document.querySelectorAll('.custom-logo')

  $logos.forEach(item => {
    if (isDarkmode) {
      if (darkLogo.src) {
        item.setAttribute('data-src', darkLogo.src)
        item.setAttribute('src', darkLogo.src)
      }
      if (darkLogo.srcset) {
        item.setAttribute('srcset', darkLogo.srcset)
      } else {
        item.removeAttribute('srcset')
      }
    } else {
      if (customLogo.src) {
        item.setAttribute('data-src', customLogo.src)
        item.setAttribute('src', customLogo.src)
      }
      if (customLogo.srcset) {
        item.setAttribute('srcset', customLogo.srcset)
      } else {
        item.removeAttribute('srcset')
      }
    }
  })
}

export { isDarkmode, toggleDarkmodeClassName, toggleCustomLogo }
