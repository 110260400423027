import { IconContext } from 'react-icons'
// import { HiOutlineSun, HiOutlineMoon } from 'react-icons/hi'
import { isDarkmode, toggleDarkmodeClassName, toggleCustomLogo } from '../darkmode-util'

const { useState } = wp.element

const DarkmodeButton = () => {
  const [darkmode, setDarkmode] = useState(isDarkmode)

  // dark / lightモードを切り替える
  const toggleLocalStorage = () => {
    setDarkmode(!darkmode)
    localStorage.theme = darkmode ? 'light' : 'dark'
  }

  const hiddenClassName = (bool = true) => {
    return bool ? 'hidden' : 'grid'
  }

  const toggleDarkmode = () => {
    toggleLocalStorage()
    toggleCustomLogo(!darkmode)
    toggleDarkmodeClassName(!darkmode)
  }

  return (
    <div onClick={() => toggleDarkmode()} className="darkmode-btn">

      <div className={`${hiddenClassName(!darkmode)} darkmode-btn-icon`}>
        <span className="material-icons-outlined">
          dark_mode
        </span>
      </div>
      <div className={`${hiddenClassName(darkmode)} darkmode-btn-icon`}>
        <span className="material-icons-outlined">
          light_mode
        </span>
      </div>

      {/* <span className="darkmode-btn-label">{darkmode ? 'ダーク' : 'ライト'}</span> */}
    </div>
  )
}

export default DarkmodeButton
