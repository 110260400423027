import { isDarkmode, toggleCustomLogo } from './darkmode-util'
import DarkmodeButton from './components/DarkmodeButton'

const { render } = wp.element
toggleCustomLogo(isDarkmode)

const $icon = document.getElementById('darkmode-btn')
if ($icon) {
  render(<DarkmodeButton />, $icon)
}
